import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, switchMap, } from 'rxjs';
import { User } from '../user/user.types';
import { CacheKey } from 'app/helpers/app-constants';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { MfaResult } from 'app/models/account/mfa-result';
import { Item, MethodResponse } from 'app/models/base-models';
import { Account } from 'app/models/account-model';
import { Ability, AbilityBuilder } from '@casl/ability';
import { Permission } from 'app/models/account/permission';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _ability = inject(Ability);
    private _userService = inject(UserService);

    private _isRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor() {
        initializeApp(environment.firebase);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem(CacheKey.accessToken, token);
    }

    get isMfaVerified(): boolean {
        const mfaValue = localStorage.getItem(CacheKey.mfaVerified);
        return mfaValue == 'true';
    }
    set isMfaVerified(isVerified: string) {
        localStorage.setItem(CacheKey.mfaVerified, isVerified);
    }

    get accessToken(): string {
        return localStorage.getItem(CacheKey.accessToken) ?? '';
    }

    set refreshToken(token: string) {
        localStorage.setItem(CacheKey.refreshToken, token);
    }

    get refreshToken(): string {
        return localStorage.getItem(CacheKey.refreshToken) ?? '';
    }
    
    get isRefreshing$(): Observable<boolean> {
        return this._isRefreshing$.asObservable();
    }

    set nextPage(url: string) {
        localStorage.setItem(CacheKey.nextPage, url);
    }

    get nextPage(): string {
        return localStorage.getItem(CacheKey.nextPage) ?? '';
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { userName: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        // if (this._authenticated) {
        //     return throwError('User is already logged in.');
        // }

        return this._httpClient.post(`${environment.apiUrl}/auth/login`, credentials).pipe(
            switchMap((user: User) => {
                // Store the access token in the local storage
                this.accessToken = user.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = user;
                localStorage.setItem(CacheKey.userInfo, JSON.stringify(user));

                // Return a new observable with the response
                return of(user);
            })
        );
    }

    updateTokens(userCredentials: { accessToken: string; refreshToken: string }) {
        this._authenticated = true;
        this.accessToken = userCredentials.accessToken;
        this.refreshToken = userCredentials.refreshToken;
    }

    /**
     * Sign in using the access token
     */
    doRefreshTokens(): Observable<boolean> {
        this._isRefreshing$.next(true);

        const auth = getAuth();
        auth.onAuthStateChanged((currentUser) => {
            if (currentUser != null) {
                currentUser.getIdToken(true)
                    .then((newToken: string) => {
                        // argh! Need to check if we have old token, set it!
                        if (this.accessToken) {
                            this.accessToken = newToken;
                            this._isRefreshing$.next(false);
                        }
                    })
            }
        })

        return of(true);
    }

    /**
     * Sign out
     */
    signOut(isLoggedOut: boolean): Observable<boolean> {
        const auth = getAuth();
        auth.signOut();

        this._httpClient
            .post(`${environment.apiUrl}/auth/revoke-token`, null)
            .subscribe(
                response => {
                    // remove user from local storage to log user out
                    return this.clearTokens();
                },
                error => {
                    // remove user from local storage to log user out
                    return this.clearTokens();
                },
            );

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: any): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}/create`, user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        if (!this.isMfaVerified) {
            return of(false);
        }

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.doRefreshTokens();
        //return of(true);
    }

    mfaCheck(): Observable<boolean> {         
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // if done, return false
        if(this.isMfaVerified) {
            return of(false);
        }

        return of(true);
    }


    clearTokens(): Observable<boolean> {

        this._authenticated = false;
        localStorage.removeItem(CacheKey.mfaVerified);
        localStorage.removeItem(CacheKey.accessToken);
        localStorage.removeItem(CacheKey.refreshToken);
        localStorage.removeItem(CacheKey.userInfo);
        localStorage.removeItem(CacheKey.nextPage);

        return of(true);
    }

    setMessage(message: string) {
        sessionStorage.setItem('infoMessage', message);
    }

    getMessage(): string {
        const message = sessionStorage.getItem('infoMessage');
        sessionStorage.removeItem('infoMessage');

        return message;
    }

    getMyPermissions(): Observable<Item[]> {
        return this._httpClient.get<Item[]>(`${environment.apiUrl}/auth/my-permissions`);
    }

    getAccessToken(account: Account): Observable<MethodResponse> {
        return this._httpClient.post<MethodResponse>(`${environment.apiUrl}/auth/access-token`, account);
    }

    checkMfa(): Observable<MfaResult> {
        return this._httpClient.post<MfaResult>(`${environment.apiUrl}/auth/mfa`, null);
    }

    validateMobileCode(code: string): Observable<MfaResult> {
        return this._httpClient.post<MfaResult>(`${environment.apiUrl}/auth/validate-mobile`, null,
            {
                params: new HttpParams().set("code", code),
            }
        );
    }

    resendMobileCode(): Observable<MfaResult> {
        return this._httpClient.post<MfaResult>(`${environment.apiUrl}/auth/resend-mobile-code`, null);
    }

    loadMyPermissions(): Observable<boolean> {
        this.getMyPermissions()
            .subscribe((permissions: any) => {
                this.updateUserAbility(permissions);
            });
        return of(true);
    }

    private updateUserAbility(permissions: Permission[]) {
        const { can, rules } = new AbilityBuilder(Ability);

        if (permissions != null && permissions.length > 0) {
            permissions.forEach(item => {
                can(item.shortName, item.groupName);
            })
        }

        this._ability.update(rules);
    }
}
